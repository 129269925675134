<template>
  <div>
    <a-form-model
      class="mb-4"
      ref="profileForm"
      :model="form"
      :rules="rules"
      @submit="handleSubmit"
    >
      <a-row>
        <a-col :span="12" class="p-2">
          <a-form-model-item ref="name" prop="name" label="Username">
            <a-input v-model="form.name"
                     placeholder="Username"
            >
              <a-icon slot="prefix" type="user" class="text-primary" />
            </a-input>
          </a-form-model-item>
        </a-col>
        <a-col :span="12" class="p-2">
          <a-form-model-item ref="email" prop="email" label="Email">
            <a-input v-model="form.email"
                     placeholder="Email"
            >
              <a-icon slot="prefix" type="mail" class="text-primary" />
            </a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
      <div v-if="checkAdminRole(user.roles)">
        <p class="font-size-16">Roles:</p>
        <a-form-model-item prop="roles" style="clear:both;">
          <a-checkbox-group v-model="form.roles"  name="roles" @change="rolesChange">
            <span>
              <a-checkbox v-for="role in allRoles" :key="role.id" :value="role.id" name="roles"  @change="rolesChange">
                {{ role.name }}
              </a-checkbox>
            </span>
          </a-checkbox-group>
        </a-form-model-item>
      </div>
      <div v-if="checkAdminRole(user.roles)">
        <p class="font-size-16">Select roles:</p>
          <a-tag class="m-1" v-for="rp in getPermissionsByRoles(form.roles)" :key="rp.id">{{ rp.name }}</a-tag>
      </div>
      <div v-if="checkAdminRole(user.roles)" class="mt-4">
        <p class="font-size-16">Select additional rights:</p>
        <a-form-model-item prop="permissions" style="clear:both;">
          <a-checkbox-group v-model="form.permissions"  name="permissions" @change="permChange">
            <span class="m-2">
              <a-checkbox v-for="perm in getDifferenceOfNotRolePermissions(form.roles)" :key="perm.id" :value="perm.id" name="permissions"  @change="permChange">
                {{ perm.name }}
              </a-checkbox>
            </span>
          </a-checkbox-group>
        </a-form-model-item>
      </div>
      <hr>
      <div class="text-right">
        <a-button type="primary" @click="handleSubmit">
          Save
        </a-button>
        <a-button style="margin-left: 1rem;" @click="closeProfileModal">
          Cancel
        </a-button>
      </div>
    </a-form-model>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import accessMix from '@/services/accessmix.js'
import store from 'store'
import { mapState } from 'vuex'

export default {
  name: 'profile.vue',
  props: ['userInfo', 'userIndex'],
  mixins: [accessMix],
  computed: {
    ...mapState(['user']),
    allRoles() {
      return this.roles_list.map(({ id, name }) => ({ id, name }))
    },
    allPermissions() {
      return this.permissions_list.map(({ id, name }) => ({ id, name }))
    },
    loading() {
      return this.processing
    },
  },
  data() {
    return {
      processing: false,
      roles_list: store.get('app.users.roles'),
      permissions_list: store.get('app.users.permissions'),
      roles: this.userInfo.roles,
      permissions: this.userInfo.permissions,
      form: {
        roles: this.userInfo.roles.map(value => value.id),
        permissions: this.userInfo.permissions.map(value => value.id),
        name: this.userInfo.name,
        email: this.userInfo.email,
        // password: '',
        // confirm: '',
      },
      rules: {
        name: [{ required: true, message: 'Please input account name', trigger: 'change' }],
        email: [{ required: true, message: 'Please input email', trigger: 'change' }],
      },
    }
  },
  mounted () {
    if (this.checkAdminRole(this.user.roles)) {
      console.log('admin role true')
    } else {
      console.log('admin role false')
      this.$emit('closeModalFunction')
    }
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault()
      this.$refs.profileForm.validate(valid => {
        if (valid) {
          this.processing = true
          this.updateProfile()
        } else {
          return false
        }
      })
    },
    closeProfileModal() {
      this.$emit('closeModalFunction')
    },
    rolesChange(e) {
      console.log(this.form.roles)
    },
    permChange(e) {
      console.log(this.form.permissions)
    },
    updateProfile() {
      const url = '/admin/user/' + this.userInfo.id
      return apiClient.patch(url, this.form).then((response) => {
        console.log(response)
        this.$notification.success({
          message: 'User account successfully updated',
          description: '',
        })
        this.processing = false
        this.$emit('submitModalFunction', response.data.data)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: 'Error while updating account',
          description: error.message,
        })
        this.processing = false
      })
    },
  },
}
</script>

<style scoped>

</style>
