import { render, staticRenderFns } from "./createModal.vue?vue&type=template&id=1eb8dcdc&scoped=true"
import script from "./createModal.vue?vue&type=script&lang=js"
export * from "./createModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1eb8dcdc",
  null
  
)

export default component.exports